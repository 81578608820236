import Example from '@/views/example'
import RouterFilter from './routerFilter'
import Home from '@/views/home'

const routerFiles = require.context('./modules/', false, /.js$/)
const routes = []
routerFiles.keys().forEach(path => {
  routes.push(...routerFiles(path).default)
})

export const menus = routes

export default [
  {
    path: '/',
    element: < Home />,
  },
  {
    path: 'example/*',
    element: < Example />,
    meta: {
      title: '例子'
    }
  },
  {
    element: < RouterFilter />,
    children: [
      ...routes,
    ]
  }
]
