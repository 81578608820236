import React from "react";
import './index.less'
import jiantou from '@/assets/images/home/jiantou.png';
import case1_bg from '@/assets/images/home/case1_bg.png';
import case2_bg from '@/assets/images/home/case2_bg.png';
import case3_bg from '@/assets/images/home/case3_bg.png';
import case4_bg from '@/assets/images/home/case4_bg.png';
import case5_bg from '@/assets/images/home/case5_bg.png';
import huakuai_slices from '@/assets/images/home/huakuai_slices.png';

export default class Case extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseList: [
        {
          title: '浙江省',
          img: case1_bg,
          contentArray: [
            {content: '省委办公厅“七张问题清单”'},
            {content: '“三服务”小管家平台'},
            {content: '未来社区数字化精益管理平台'},
            {content: '常态化核酸检测 组织管理系统'},
            {content: '基层治理四平台'},
            {content: '...'}
          ]
        },
        {
          title: '科技部试点项目',
          img: case2_bg,
          contentArray: [
            {content: '新疆库尔勒智慧社区', styleName: 'content-item-p'}
          ]
        },
        {
          title: '民政部试点项目',
          img: case3_bg,
          contentArray: [
            {content: '杭州滨江智慧社区', styleName: 'content-item-p'},
            {content: '杭州拱墅民生保障和社区微脑平台', styleName: 'content-item-p'}
          ]
        },
        {
          title: '住建部课题',
          img: case4_bg,
          contentArray: [
            {content: '十四五科技发展规划课题', styleName: 'content-item-p'}
          ]
        },
        {
          title: '西安市',
          img: case5_bg,
          contentArray: [
            {content: '最多跑一次', styleName: 'content-item-p'}
          ]
        }
      ]
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className='case-wrap'>
        <div className='base-width'>
          <div className='case-wrap-div '>
            <div className='case-head'>
              <h1>公司案例</h1>
              <h3>精数据、优服务，每一个案例都是经过深思熟虑的考量为客户构建项目</h3>
            </div>
            <div className='case-body'>
              {
                this.state.caseList.map((item, index) => {
                  return <div className={'case' + (index + 1)} key={index}>
                    <img src={item.img} className='case-img'/>
                    <img src={huakuai_slices} className='slider-img'/>
                    <div className='case-info'>
                      <div className="title">
                        {item.title}
                      </div>
                      <div className="content">
                        {
                          item.contentArray.map((itemChild, indexChild) => {
                            return <div className="content-item" style={style[itemChild.styleName]} key={indexChild}>
                              {(itemChild.content && itemChild.content !== "...") && <img src={jiantou}/>}
                              <span>{itemChild.content}</span>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const style = {
  'content-item-p': {
    width: '100%'
  }
}