import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken () {
  return Cookies.get(TokenKey)
}

/**
 * js-cookie 未设置过期时间，默认情况下cookie有效期截止至用户退出浏览器。
 * @param token 设置的token
 * @returns {*}
 */
export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getDataFromJwtToken (token) {
  if (!token) {
    token = getToken()
  }
  if (!token) {
    return null
  }
  var data = token.split('.')[1]
  if (!data) {
    return null
  }
  return JSON.parse(window.atob(data))
}
