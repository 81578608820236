import service from './index.js'

export const _post = (url, params) => {
  return new Promise((resolve, reject) => {
    service.post(url, params).then((response) => {
      resolve(response)
    }).catch((reason) => {
      reject(reason)
    })
  })
}

export const _get = (url, params) => {
  return new Promise((resolve, reject) => {
    service.get(url, { params: params }).then((response) => {
      resolve(response)
    }).catch((reason) => {
      reject(reason)
    })
  })
}

export const _delete = (url, params) => {
  return new Promise((resolve, reject) => {
    service.delete(url, { params: params }).then((response) => {
      resolve(response)
    }).catch((reason) => {
      reject(reason)
    })
  })
}

export const _sendRequest = (options) => {
  return new Promise((resolve, reject) => {
    service.request(options).then((response) => {
      resolve(response.data)
    }).catch((reason) => {
      reject(reason.data)
    })
  })
}
