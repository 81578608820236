import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import { } from 'antd'
// import {connect} from 'react-redux'
// import {} from '@actions/xxx'

// @connect((storeState)=>({}))
export default class example extends Component {

  static defaultProps = {
  }

  static propTypes = {
  }

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount () {
  }

  render () {
    return (
      <div style={styles.page}>
        示范页面
      </div>
    )
  }
}

const styles = {
  page: {
    height: "100%",
  },
};