import React from 'react';
import router from '../router'
// import store from '../store'
import { message } from 'antd'
import { removeToken } from '@/utils/token'

let isLoading = false

export function reLogin (data) {
  if (isLoading) return
  isLoading = true

  message.error('登录信息失效')

  // store.dispatch('user/forcedLogOut').then(() => {
  //   if (React.$socket) React.$socket.onexit()
  //   removeToken()
  //   router.replace({
  //     name: 'Login',
  //     query: { redirect: router.currentRoute.fullPath }
  //   })
  //   setTimeout(() => {
  //     isLoading = false
  //   }, 500)
  // })
}
