import React from 'react'

import phone from '@/assets/images/home/phone.png';

const offset = 120
let isClickScroll = false
export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navList: [
        {
          label: '首页',
          value: 0,
          href: '#top'
        },
        {
          label: '产品服务',
          value: 1,
          href: '#product-service'
        },
        {
          label: '公司案例',
          value: 2,
          href: '#case'
        },
        {
          label: '关于设维',
          value: 3,
          href: '#aboutUs'
        },
      ],
      current: 0,
      scrollTop: 0 // 页面滚动高度
    };
  }

  componentDidMount () {
    React.$bus.on('scrollHome', this.scrollHomeEvent)
  }

  componentWillUnmount () {
    React.$bus.off('scrollHome')
  }

  onNavItem = (value) => {
    this.setState({
      current: value
    })

    isClickScroll = true

    setTimeout(() => {
      isClickScroll = false
    }, 800)
  }

  scrollHomeEvent = ({ el, locationPoint }) => {
    const { scrollTop } = el
    let current = this.state.current
    const length = locationPoint.length

    if (isClickScroll) {
      return this.setState({ scrollTop })
    }

    for (let i = length - 1; i >= 0; i--) {
      if (scrollTop >= locationPoint[i] - offset) {
        current = i
        break
      }
    }

    this.setState({
      scrollTop,
      current
    })
  }

  render () {
    const { navList, current, scrollTop } = this.state

    const opacity = scrollTop / 4000

    const boxShadowOpacity = opacity > 0.1 ? 0.1 : opacity

    return (
      <div className='nav-bar-wrap'
        style={{
          background: `rgba(255,255,255, ${scrollTop / 200})`,
          boxShadow: `0px 0px 40px rgba(42, 97, 255, ${boxShadowOpacity})`
        }}>
        <div className='base-width between-row center-align'
        // style={{ padding: '0 120px' }}
        >
          <div className='center-align'>
            <div className='logo'></div>
            <div className='nav-list center-align'>
              {navList.map(item => {
                const isActive = item.value === current ? 'nav-item-active' : ''

                return (
                  <a href={item.href}>
                    <div
                      className={`nav-item pointer ${isActive}`}
                      key={item.value}
                      onClick={() => { this.onNavItem(item.value) }}>
                      {item.label}
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
          <div className='connect center-align'>
            <img src={phone} />
            <span className='ml8'>0571-88827507</span>
          </div>
        </div>
      </div >
    )
  }
}