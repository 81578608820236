import { createStore } from 'redux'

const initState = Object.create(null)

const initMutations = Object.create(null)

const initActions = Object.create(null)


const files = require.context('./modules/', false, /.js$/)
files.keys().forEach(key => {
  const path = key.replace(/.\/|.js/g, '')
  const { state, mutations, actions } = files(key).default
  setAttributes(state, initState)
  setAttributes(mutations, initMutations, path)
  setAttributes(actions, initActions, path)
})

function setAttributes (source, target, path) {
  Object.keys(source).forEach(key => {
    const modifier = path ? `${path}/` : ''
    target[modifier + key] = source[key]
  })
  Object.freeze(target)
}

const reducer = (state = initState, action) => {
  const mutation = initMutations[action.type]

  if (!mutation) return state

  const newState = mutation(state, action)

  return { ...state, ...newState }
};

const asyncDispatch = (config) => {
  const action = initActions[config.type]

  if (!action) {
    throw new Error(`不存在 type 为 ${config.type} 的 action`)
  }

  return action(config)
}

const store = createStore(reducer);
store.asyncDispatch = asyncDispatch

export default store