import React from "react";
import { ReactSVG } from 'react-svg'

import './index.less'

import dzjg from '@/assets/images/product/dzjg.png';
import jczt from '@/assets/images/product/jczt.png';
import xzdn from '@/assets/images/product/xzdn.png';
import snxt from '@/assets/images/product/snxt.png';
import zbxt from '@/assets/images/product/zbxt.png';
import mqxt from '@/assets/images/product/mqxt.png';
import dcpm from '@/assets/images/product/dcpm.png';
import circle_left from '@/assets/images/product/circle-left.png';
import circle_right from '@/assets/images/product/circle-right.png';

export default class ProductService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftList: [
        {
          title: '党政机关整体智治系统',
          content: '决策执行一屏掌控，执行反馈一览无余，重大任务一键智达，基层智治一贯到底，问题堵点多跨协同',
          icon: dzjg
        },
        {
          title: '基层整体智治系统',
          content: '围绕党政机关工作，形成完整工作体系，做到决策有依据、执行有保障、过程有监督、成效有考评。',
          icon: jczt
        },
        {
          title: '写作大脑',
          content: '写作大脑是创新型写作信息化协同管理平台。平台以智能辅助写作为核心，通过知识体系化、业务协同化、写作智能化等方式，实现文稿写作全链条闭环管理。打造智能写作能力的智能化文稿写作辅助工具。',
          icon: xzdn
        }
      ],
      rightList: [
        {
          title: '首脑系统—上接天线（管方向）',
          content: '深入学习领会总书记指示精神，准确理解把握党中央、省市委决策部署和工作要求，提高政治领悟力和判断力，把准工作方向。',
          icon: snxt
        },
        {
          title: '专班系统—中强落实（管队伍）',
          content: '适应变革型组织，探索变革性实践，支撑专班化运作，形成首脑决策任务的发布、分派、执行和过程监督全链条，形成强有力、不走偏的决策贯彻执行链。',
          icon: zbxt
        },
        {
          title: '民情系统—下接地气（管机制）',
          content: '建设社区民情驿站，对接三服务、民呼我为、数字政协、数字人大等系统，以基层主动走访、网络舆情采集、群众反应等形式采集分析社情民意，衡量执行效果，对决策调优提供依据。',
          icon: mqxt
        }
      ],
    };
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  render () {
    const { leftList, rightList } = this.state
    return (
      <div className="product">
        <div className='base-width'>
          <div className="product-content f-center">
            <div className="product-left">
              {leftList.map((leftItem, leftIndex) => {
                const isLeftItem = leftIndex === 1 ? 'product-item-left' : ''
                return (
                  <div className={`product-item ${isLeftItem}`}
                    key={leftIndex}>
                    <img src={leftItem.icon} />
                    <div className="title">{leftItem.title}</div>
                    <div className="content">{leftItem.content}</div>
                  </div>
                )
              })}
            </div>
            <div className="product-middle">
              <div className="product-main">
                <img src={circle_left} className="circle-left" />
                <img src={circle_right} className="circle-right" />
                <div className="title">产品服务</div>
                <div className="content">数字孪生，不仅是技术的孪生，更是部门职责的孪生，业务流程的孪生，数字系统最终要可用、好用、可管、可控，发挥实战实效的管理作用。</div>
              </div>
              <div className="product-item">
                <img src={dcpm} />
                <div className="title">DPCM</div>
                <div className="content">利用数字化思维、数字化认知和数字化技术，构建基于数字化的项目协同管理系统。</div>
              </div>
            </div>
            <div className="product-right">
              {rightList.map((rightItem, rightIndex) => {
                const isRightItem = rightIndex === 1 ? 'product-item-right' : ''
                return (
                  <div className={`product-item ${isRightItem}`}
                    key={rightIndex}>
                    <img src={rightItem.icon} />
                    <div className="title">{rightItem.title}</div>
                    <div className="content">{rightItem.content}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}