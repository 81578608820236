import React, { createRef } from 'react'
import { ReactSVG } from 'react-svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import NavBar from './NavBar';
import './index.less'

import banner1 from '@/assets/images/home/banner1.gif';
import banner2 from '@/assets/images/home/banner2.gif';


import menu_gaijin from '@/assets/images/home/menu_gaijin.png';
import menu_guanli from '@/assets/images/home/menu_guanli.png';
import menu_jueche from '@/assets/images/home/menu_jueche.png';
import menu_zhixing from '@/assets/images/home/menu_zhixing.png';

import menu_gaijin_gif from '@/assets/images/home/menu_gaijin.gif';
import menu_guanli_gif from '@/assets/images/home/menu_guanli.gif';
import menu_jueche_gif from '@/assets/images/home/menu_jueche.gif';
import menu_zhixing_gif from '@/assets/images/home/menu_zhixing.gif';

import step_gzbs from '@/assets/images/home/step_gzbs.png';
import step_jcsc from '@/assets/images/home/step_jcsc.png';
import step_jcyc from '@/assets/images/home/step_jcyc.png';
import step_rwtc from '@/assets/images/home/step_rwtc.png';
import step_sgzb from '@/assets/images/home/step_sgzb.png';
import step_wttc from '@/assets/images/home/step_wttc.png';
import step_arrow from '@/assets/images/home/step_arrow.png';

let timer = null
export default class BaseHead extends React.Component {
  constructor(props) {
    super(props);

    this.swiperRef = createRef()

    this.state = {
      swpierList: [
        {
          textList: ['专业从事数字政府和智慧社区', '数字化解决方案'],
          icon: banner1,
        },
        {
          textList: ['致力于成为全国领先的', '数字化管理软件和服务提供商'],
          icon: banner2,
        }
      ],
      current: 0,
      menuList: [
        { label: '决策', describe: '运筹决策，一屏掌控', value: 1, icon: menu_jueche, gif: menu_jueche_gif },
        { label: '管理', describe: '统揽全局，协调各方', value: 2, icon: menu_guanli, gif: menu_guanli_gif },
        { label: '执行', describe: '贯彻执行，深度落实', value: 3, icon: menu_zhixing, gif: menu_zhixing_gif },
        { label: '改进', describe: '流程优化，持续改进', value: 4, icon: menu_gaijin, gif: menu_gaijin_gif },
      ],
      hoverMenuIndex: null,
      stepList: [
        { text: '问题统筹', icon: step_wttc },
        { text: '任务统筹', icon: step_rwtc },
        { text: '数改专班', icon: step_sgzb },
        { text: '工作部署', icon: step_gzbs },
        { text: '决策生成', icon: step_jcsc },
        { text: '决策运筹', icon: step_jcyc },
      ]
    };
  }

  componentDidMount () {
    this.swiperAutoplay()
  }

  componentWillUnmount () {
    timer && clearInterval(timer)
  }

  onMouseRenterMenu = (index) => {
    this.setState({
      hoverMenuIndex: index
    })
  }

  onMouseLeaveMenu = () => {
    this.setState({
      hoverMenuIndex: null
    })
  }

  onSlideChange = ({ activeIndex }) => {
    this.setState({
      current: activeIndex
    })
  }

  onPagination = (index) => {
    const { swiper } = this.swiperRef.current

    swiper.slideTo(index)
  }

  swiperAutoplay = () => {
    const delay = 5000

    timer = setInterval(() => {
      let { current, swpierList } = this.state

      if (swpierList.length - 1 === current) {
        current = 0
      } else {
        current++
      }

      this.onPagination(current)
    }, delay)
  }


  // 渲染轮播图
  renderSwiper = () => {
    const { swpierList, current } = this.state

    return (
      <div className='bg-linear'>
        <div className='base-width relative'>
          <Swiper
            ref={this.swiperRef}
            direction='vertical'
            className='swiper'
            onSlideChange={this.onSlideChange}>
            {
              swpierList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className='swiper-item center-align between-row'>
                      <div className='ft36 bold'>
                        {item.textList.map((text) => <p key={text}>{text}</p>)}
                      </div>
                      <img className='icon' src={item.icon} />
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <div className='swiper-pagination'>
            {
              swpierList.map((item, index) => {
                const isActive = current === index ? 'swiper-pagination-bullet-active' : ''
                return (
                  <div
                    className={`swiper-pagination-bullet ${isActive}`}
                    key={index}
                    onClick={() => { this.onPagination(index) }}></div>
                )
              })
            }
          </div>
        </div>
      </div >
    )
  }

  renderMenu = () => {
    const { menuList, hoverMenuIndex } = this.state
    return (
      <div className='menu-module'>
        <div className='base-width'>
          <div className='menu between-row'>
            {
              menuList.map((item, index) => {
                const isHover = hoverMenuIndex === index
                return (
                  <div className='menu-item center-align' key={index}
                    onMouseEnter={() => this.onMouseRenterMenu(index)}
                    onMouseLeave={this.onMouseLeaveMenu}>
                    <div className='menu-item-icon mr16'>
                      <img src={isHover ? item.gif : item.icon} />
                    </div>
                    <div>
                      <div className='menu-item-label ft18 bold'>{item.label}</div>
                      <div className='menu-item-describe'>{item.describe}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  renderStep () {
    const { stepList } = this.state
    return (
      <div className='step relative'>
        {this.renderMenu()}
        <div className='primary-color ft20 tc slogan bold'>一体化的管理、业务、数据、技术孪生输出</div>
        <div className='base-width'>
          <div className='list f-center'>
            {
              stepList.map((item, index) => {
                return (
                  <div className='step-item center-align' key={index}>
                    <div className='tc info'>
                      <img className='step-item-icon' src={item.icon} />
                      <p className='mt16'>{item.text}</p>
                    </div>
                    {
                      stepList.length - 1 !== index ?
                        <div className='arrow f-center'>
                          <img src={step_arrow} />
                        </div> : ''
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className='base-head-wrap'>
        <NavBar />
        {this.renderSwiper()}
        {this.renderStep()}
      </div>
    )
  }
}