/**
 * 路由过滤器
 */
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const locationCache = Object.create(null);

function RouterFilter() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (locationCache.pathname !== pathname) {
      locationCache.pathname = pathname;
      console.log("路由刷新了");
    }

    // navigate('login')
  });

  return <Outlet />;
}

export default RouterFilter;
