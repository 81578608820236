import React from 'react'
import './index.less'

import gs_weixin from '@/assets/images/home/gs_weixin.jpg';
import gs_zhaopin from '@/assets/images/home/gs_zhaopin.jpg';

export default class BaseFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <div className='base-footer-wrap'>
        <div className='base-width f-column between-row'>
          <div className='between-row'>
            <div className='center-align'>
              <div className='connect' style={{ marginRight: '120px' }}>
                <p className='name'>关注我们</p>
                <p className='link'>企业官网：www.so-well.cn</p>
              </div>
              <div className='connect'>
                <p className='name'>友情链接</p>
                <p className='link'>杭州网格：www.hzgrid.com</p>
              </div>
            </div>
            <div className='center-align mt40'>
              <div className='qrCode mr40 tc'>
                <img src={gs_weixin} />
                <p>公司微信</p>
              </div>
              <div className='qrCode tc'>
                <img src={gs_zhaopin} />
                <p>公司招聘</p>
              </div>
            </div>
          </div>

          <div className='rights tc'>
            <span>© 2013-2016 杭州设维信息技术有限公司 All Rights Reserved.</span>
            <span style={{ margin: '0 40px' }}>浙ICP备16008150号-1</span>
            <span>浙公网安备 33010502002659号</span>
          </div>
        </div>
      </div>
    )
  }
}