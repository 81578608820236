import React from 'react';

// 自动化导出
const files = require.context('./modules/', true, /\.js$/)

const api = {}

files.keys().forEach(path => {
  const pathList = path.split('/')
  const fileName = pathList[pathList.length - 1].split('.js')[0]

  api[fileName] = files(path).default || files(path)
})

React.$api = api

export { default as loginApi } from './modules/loginApi'
