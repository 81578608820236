import Home from '@/views/home'

export default [
  {
    path: '/home',
    element: < Home />,
    meta: {
      title: '首页'
    },
  }

]