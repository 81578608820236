import React from "react";
import './index.less'
import company_course from '@/assets/images/home/company_course.png';
import dinwei_icon from '@/assets/images/home/dinwei_icon.png';
import email_icon from '@/assets/images/home/email_icon.png';
import phone_icon from '@/assets/images/home/phone_icon.png';
import youbian_icon from '@/assets/images/home/youbian_icon.png';
import address_icon from '@/assets/images/home/address_icon.png';
import dianzhui_icon from '@/assets/images/home/dianzhui_icon.png';

export default class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    var map = new AMap.Map('map', {
      zoom: 15.99,//级别 3-17  越大显示越细节
      center: [120.130831, 30.273410],//中心点坐标
      viewMode: '3D',//使用3D视图
      mapStyle: 'amap://styles/813020ed978cb570b162789bc01fb7f9', //设置地图的自定义主题
    });
    //点位加载
    var marker = new AMap.Marker({
      content: '<img src="' + address_icon + '" class="address-icon"/>',  // 自定义点标记覆盖物内容
      position: new AMap.LngLat(120.130831, 30.271057),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      offset: new AMap.Pixel(-60, -70),
      title: '现代国际大厦',
    });
    marker.on('mouseover', (e) => {
      console.log('eee', e)
    });
    map.add(marker)
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <div className='aboutUs-wrap'>
        <div className='base-width'>
          <div className='aboutUs-head'><h1>关于设维</h1></div>
          <div className='aboutUs-body flex'>
            <div className='aboutUs-mp' id="map"></div>
            <div className='aboutUs-content'>
              <p>
                杭州设维信息技术有限公司（以下简称杭州设维）成立于2009年，是专业从事数字政府系统开发、建设、运营和服务的高新技术企业，尤其在党建统领的政府数字化改革领域，具有独到的、体系化的理念和解决方案。
              </p>
              <p>
                公司创始人张丽霞女士是资深的供应链专家，曾在贝尔、阿尔卡特、阿里巴巴运用供应链、产业链、人才链的方法，成功解决了企业“巨复杂系统”的有序、高效的运营疑难问题。设维公司以巨复杂系统中的“负熵有序”理论作为架构指导理论；以供应链的动态机理作为技术方法论；以“巨复杂系统”中必然存在一个“大道至简”的解决路径为导向，带领公司同仁积极参与浙江省“最多跑一次”、浙江省委的“三服务”小管家系统、未来社区精益化数字平台、浙江152（1612）数字化改革中的主赛道“党政机关整体智治”和“七张问题清单”等系统的顶层设计、系统开发、建设运营，其中“七张问题清单”获得2021年度浙江省数字化改革突破奖第一名，2022年继续为152（1612）数字化改革这巨复杂系统构建、开发和迭代“写作大脑”、“专班在线”、“基层智治”等数字系统。通过以上建设的锤炼，杭州设维积累了丰富的巨复杂系统的数字化理念、方法论、顶层思维专家人才链和实战实施经验。

              </p>
            </div>
            <div className='company-tips'>
              {/*<span>现代国际大厦</span>*/}
              <div className='company-tips-info'>
                <img src={dianzhui_icon} style={style['dianzhui-img']} />
                <div className='tips-info-item center-align mb16'>
                  <img src={dinwei_icon} className='mr12' />
                  <div className='content'>中国 · 杭州 · 天目山路159号现代国际大厦B座7F</div>
                </div>
                <div className='tips-info-item center-align'>
                  <div className="center-align mr32">
                    <img src={phone_icon} className='mr12' />
                    <div className='content text-nowarp'>0571-88827507</div>
                  </div>
                  <div className="center-align mr32">
                    <img src={email_icon} className='mr12' />
                    <div className='content text-nowarp'>services@so-well.cn</div>
                  </div>
                  <div className="center-align">
                    <img src={youbian_icon} className='mr12' />
                    <div className='content text-nowarp'>310012</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={company_course} className='img-company_course' />
        </div>
      </div>
    )
  }
}

const style = {
  'dianzhui-img': {
    position: 'absolute',
    bottom: '0',
    right: '32px',
    width: '140px',
    height: '100px'
  }
}