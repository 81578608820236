import store from '../index'

const defaultState = {
  count: 0,
  time: new Date().getTime(),
}

const mutations = {
  INCREMENT (state, action) {
    const { count } = state
    return { count: count + 1 }
  },

  SET_TIME (state, action) {
    if (action.value) {
      return {
        time: action.value
      }
    }
    return { time: new Date().getTime() };
  }
}

const actions = {
  setTime (config) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        dispatch({ type: 'SET_TIME' })
        resolve(store.getState())
      }, 1000)
    })
  }
}

function dispatch (action) {
  store.dispatch({
    ...action,
    type: 'home/' + action.type
  })
}

export default {
  state: defaultState,
  mutations,
  actions
}