import { _post, _get } from '@/request/fun'

const baseServerAddr = '/interface'

const loginApi = {
  // 账号登录
  login (params) {
    return _post(baseServerAddr + '/login', params)
  },
  // 获取用户信息
  getUserByToken (token) {
    return _get('/api/project/projectUser/getUserByToken', { token })
  },
  // 手机号/邮箱 登录
  sms (params) {
    return _post(baseServerAddr + '/login/sms', params)
  },
  // 发送验证码
  sendCode (params) {
    return _post(baseServerAddr + '/login/sms/send', params)
  }
}

export default loginApi
