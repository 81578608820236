import React, { Component, createRef } from "react";
import BaseHead from './components/BaseHead';
import BaseFooter from './components/BaseFooter';
import ProductService from './components/ProductService';
import Honor from './components/Honor';
import Case from './components/Case';
import AboutUs from './components/AboutUs';

export default class Home extends Component {
  static defaultProps = {};

  static propTypes = {};

  constructor(props) {
    super(props);

    this.scrollBoxRef = createRef()
    this.productServiceRef = createRef()
    this.caseRef = createRef()
    this.aboutUsRef = createRef()

    this.state = {
    };
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  scroll = (e) => {
    React.$bus.emit('scrollHome', {
      el: this.scrollBoxRef.current,
      event: e || {},
      locationPoint: [
        0, // 页顶
        this.productServiceRef.current.offsetTop,
        this.caseRef.current.offsetTop,
        this.aboutUsRef.current.offsetTop,
      ]
    })
  }

  render () {
    return (
      <div
        ref={this.scrollBoxRef}
        style={style['home-wrap']}
        onScroll={this.scroll}
      >
        {/* 顶部锚点 */}
        <div id="top"></div>

        <BaseHead />

        {/* 产品服务 */}
        <div id='product-service' ref={this.productServiceRef}>
          <ProductService />
        </div>

        {/* 荣誉资质 */}
        <Honor />

        {/* 公司案例 */}
        <div id='case' ref={this.caseRef} >
          <Case />
        </div>

        {/* 关于设维 */}
        <div id='aboutUs' ref={this.aboutUsRef}>
          <AboutUs />
        </div>

        <BaseFooter />
      </div>
    )
  }
}

const style = {
  'home-wrap': {
    minWidth: '1440px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    scrollBehavior: 'smooth'
  }
}
