/**
 * 路由配置页面
 */
import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import routes from "./routes";

export default class RouterView extends Component {
  constructor(props) {
    super(props);
  }

  RouteList(list, fatherKey) {
    return list.map((config, index) => {
      const { path, element, children, redirectTo } = config;

      if (redirectTo) {
        return (
          <Route
            path={path}
            element={<Navigate to={redirectTo} />}
            key={`redirectTo-${fatherKey}-${index}`}
          />
        );
      }

      return (
        <Route path={path} element={element} key={`${fatherKey}-${index}`}>
          {children && this.RouteList(children)}
        </Route>
      );
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>{this.RouteList(routes)}</Routes>
      </BrowserRouter>
    );
  }
}

// 配置形式
// import { BrowserRouter as Router, useRoutes } from "react-router-dom";

// const RenderRoutes = () => {
//   return useRoutes(routes);
// };

// export default function RouterView() {
//   return (
//     <Router>
//       <RenderRoutes />
//     </Router>
//   );
// }
