import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import { EventEmitter } from 'events';
import RouterView from './router/index'
import reportWebVitals from './reportWebVitals';
import store from '@/store'
import request from './request'
import '@/assets/styles/index.less'
import '@/request/api.js' // 引入接口


const { serverConfig } = require('./config.js')

React.$store = store
React.$bus = new EventEmitter() // 全局事件通知

function render () {
  ReactDOM.render(
    <React.StrictMode>
      <RouterView />
    </React.StrictMode>,
    document.getElementById('root')
  );

  reportWebVitals();
}
// if (process.env.NODE_ENV === 'development') {
React.$configApiUrl = serverConfig
render()
// } else {
//   request.get('serverConfig.json').then((result) => {
//     React.$configApiUrl = result
//     render()
//   }).catch((error) => {
//     console.log('getConfigJson Error!', error)
//   })
// }
