import React from "react";
import './index.less'

import logo1 from '@/assets/images/honor/logo1.png';
import logo2 from '@/assets/images/honor/logo2.png';
import logo3 from '@/assets/images/honor/logo3.png';
import ryzz from '@/assets/images/honor/ryzz.png';
import ryzzGif from '@/assets/images/honor/ryzz.gif';


export default class Honor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftList: [
        {
          title: '浙江省软件行业协会成员企业',
          icon: logo3
        },
        {
          title: '中国信息协会大数据分会',
          icon: logo1
        },
        {
          title: '国家级科技孵化器内企业',
          icon: logo2
        }
      ],
      rightList: [
        {
          title: '浙江省软件企业',
          icon: logo3
        },
        {
          title: '国家高新技术企业',
          icon: logo2
        },
      ]
    };
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  render () {
    const { leftList, rightList } = this.state
    return (
      <div className="honor">
        <div className='base-width f-center'>
          <div className="honor-left">
            {leftList.map((leftItem, leftIndex) => {
              return (
                <div className={`honor-item left${leftIndex}`}
                  key={leftIndex}>
                  <img src={leftItem.icon} />
                  <span className="title">{leftItem.title}</span>
                </div>
              )
            })}
          </div>
          <div className="honor-middle">
            <div className="imgDiv">
              <img src={ryzzGif} className="imgGif" />
              <img src={ryzz} />
            </div>
            <div className="middle-title">荣誉资质</div>
          </div>
          <div className="honor-right">
            {rightList.map((rightItem, rightIndex) => {
              return (
                <div className={`honor-item right${rightIndex}`}
                  key={rightIndex}>
                  <img src={rightItem.icon} />
                  <span className="title">{rightItem.title}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}