module.exports = {
  title: 'test app',
  navbar: {
    // 是否显示头部 Logo
    showLogo: true,
    // 头部是否显示侧边栏展开按钮
    showSidebarExpandBtn: false
  },
  sidebar: {
    show: true,
    // 侧边栏顶部是否显示展开按钮
    showSidebarExpandBtn: true
  },
  serverConfig: {
    // 服务器请求地址
    REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  }
}
